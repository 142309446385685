import * as React from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import slugify from '@sindresorhus/slugify';

// Components
import Layout from '../components/layout/Layout';

// Images
import headerBackgroundImage from '../images/svg/components/card-11.svg';

const CitiesPage = ({data}) => {
  
  const {t} = useTranslation();

  return (
     <Layout 
      pageType="classic" 
      pageTitle={t('meta.title')} 
      pageDescription={t('meta.description', { title: data.site.siteMetadata.title })} 
      pageKeywords={t('meta.keywords', { title: data.site.siteMetadata.title })} 
      headerClass="navbar-absolute-top navbar-light" 
      headerStyle="light"
    >      
      <div>
        <div className="bg-img-start" style={{backgroundImage: 'url(' + headerBackgroundImage + ')'}}>
          <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
            <div className="w-md-75 w-lg-50 text-center mx-md-auto">
              <h1>{t('title')}</h1>
              <p>{t('subtitle', { title: data.site.siteMetadata.title })}</p>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          {data.cities &&
            <div className="w-lg-75 mx-lg-auto mb-5 mb-md-7">
              <ul className="list-group list-group-lg">
                {data.cities.nodes.map((city, index) => (
                  <li key={index} className="list-group-item">
                    <div className="row justify-content-center align-items-center">
                      <div className="text-center border-bottom pb-3 mb-3">
                        <span className="h2">{city.name}</span>
                      </div>
                      <div className="col-sm-9 mb-2 mb-sm-0">
                        <span>{t('city.description', { title: data.site.siteMetadata.title, city_name: city.name, department: city.department, postal_code: city.postal_code })}</span>
                      </div>
                      <div className="col-sm-3 text-sm-end">
                        <Link className="card-link ms-sm-0 ms-md-2" to={`/${slugify(city.name)}/`}>{t('cta.learnMore')} <i className="bi-chevron-right small ms-1 small ms-1"></i></Link>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          }
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>  
        </div>   
      </div>
    </Layout>
  )
}

export default CitiesPage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "cities", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cities: allCitiesCsv (
      filter: { active: { eq: "1" } }
      sort: {fields: name, order: ASC}
    ) {
      nodes {
        name
        name_capital
        name_lowercase
        name_slug
        department
        postal_code
        population
        longitude
        latitude
        active
      }
      totalCount
    }
  }
`;
